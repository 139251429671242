import { useRouter } from 'next/router'

import ButtonBlue from '../components/ui/button/_color/_blue'

import styles from './404.module.scss'

export default function Custom404() {
  const router = useRouter()

  return (
    <div className={styles.page404}>
      <div className={styles.bg}></div>
      <h1>Ooops. That’s definitely the wrong door</h1>
      <ButtonBlue className={styles.btn} onClick={() => router.push('/')}>
        Go back home
      </ButtonBlue>
    </div>
  )
}
